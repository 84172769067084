var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"invest-list"},[_c('div',{staticClass:"category"},[_c('div',{staticClass:"tab-heading"},[_vm._l((_vm.ListService),function(item){return _c('div',{key:("header-project-" + (item.service_id)),staticClass:"tab-item",class:_vm.tabActiveProps == item.service_id ? 'tab-active' : '',on:{"click":function($event){_vm.tabActiveProps = item.service_id}}},[_vm._v(" "+_vm._s(item.service_name)+" ")])}),_c('div',{staticClass:"tab-item more-category"},[_c('b-button',[_vm._v(" Khác ")])],1)],2),(
                _vm.ListProject &&
                _vm.ListProject.list &&
                _vm.ListProject.list.data &&
                _vm.ListProject.list.data.length > 0
            )?_c('b-row',{staticClass:"category-content"},[_vm._l((_vm.ListProject.list.data),function(project){return _c('b-col',{key:("project-" + (project.project_id)),attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"project-item"},[_c('div',{staticClass:"project-img"},[_c('img',{attrs:{"src":_vm.ListProject.base_url + project.project_avata,"alt":""}}),_c('div',{staticClass:"content-project"},[_c('h3',[_vm._v(_vm._s(project.project_name))]),_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(project.project_introduce)}})])]),_c('div',{staticClass:"project-name"},[_c('router-link',{attrs:{"to":{
                                name: 'ProjectDetail',
                                params: { id: project.project_slug },
                            }}},[_c('p',[_vm._v(" "+_vm._s(project.project_name)+" ")])])],1),_c('div',{staticClass:"project-line"})])])}),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pagination"},_vm._l((_vm.ListProject.list.last_page),function(indexPage){return _c('div',{key:indexPage,staticClass:"dot",class:_vm.page == indexPage ? 'dot-active' : '',on:{"click":function($event){_vm.page = indexPage}}})}),0)])],2):_c('b-row',{staticClass:"category-content"},[_c('b-col',{attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.$t('temporarilyNoProjects')))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }