<script>
import { mapGetters } from 'vuex';
import ListProject from './ListProject_in.vue';

export default {
    components: { ListProject },
    data() {
        return {
            key: '',
            tabActive: 1,
        };
    },
    computed: {
        ...mapGetters({
            ListService: 'project/ListService',
        }),
    },
    methods: {
        onSearchTextProject() {
            this.$store.dispatch('project/get_listProject', {
                type: 'calling',
                project_service: this.tabActive,
                key: this.key,
            });
        },
        changeTabActiveInParent(tabActive) {
            this.tabActive = tabActive;
        },
    },
    mounted() {},
    created() {
        this.$store.dispatch('project/get_listService');
    },
};
</script>

<template>
    <b-container fluid class="investing">
        <b-container class="banner">
            <img
                src="~@/assets/images/mock/project/invest.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('project') }} {{ $t('investing') }}</div>
                <div class="input">
                    <b-button @click="onSearchTextProject">
                        <img src="~@/assets/images/icons/search.png" alt="" />
                    </b-button>
                    <b-form-input
                        :placeholder="$t('search')"
                        v-model="key"
                    ></b-form-input>
                </div>
            </div>
        </b-container>
        <ListProject
            :ListServiceProps="ListService"
            :tabActiveProps="tabActive"
            @changetabActiveProps="changeTabActiveInParent"
        />
    </b-container>
</template>
<style lang="scss">
.investing {
    padding: 50px 0;
    .banner {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        @media (min-width: 1400px) {
            max-width: 1320px;
        }
        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 40px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
            .input {
                border: 1px solid #000093;
                background: rgba($color: #ffffff, $alpha: 0.4);
                height: 45px;
                width: 100%;
                max-width: 420px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                button {
                    flex: 0 0 60px;
                    height: 45px;
                    padding: 5px;
                    background: transparent;
                    border: 0;
                    outline: none;
                    box-shadow: none;
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                input {
                    border: 0;
                    background: transparent;
                    box-shadow: none;
                    color: #fff;
                    font-weight: 500;
                    &::placeholder {
                        color: #fff;
                        opacity: 0.9;
                    }
                }
            }
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
    @media (max-width: 575px) {
        padding: 25px 10px;
        .banner {
            min-height: 250px;
        }
    }
}
</style>
