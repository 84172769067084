<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        ListServiceProps: {
            type: Array,
            default: () => [],
        },
        tabActiveProps: {
            type: Number,
            default: () => 1,
        },
    },
    data() {
        return {
            page: 1,
            listPage: 3,
            width: 100,
            key: '',
        };
    },
    computed: {
        ...mapGetters({
            ListProject: 'project/ListProject',
        }),
        ListService() {
            const { width } = this.width;
            const list = this.ListServiceProps;
            if (width >= 700 && width <= 991) {
                return list.slice(0, 4);
            }
            if (width >= 576 && width <= 700) {
                return list.slice(0, 3);
            }
            if (width >= 414 && width <= 575) {
                return list.slice(0, 2);
            }
            if (width <= 414) {
                return list.slice(0, 1);
            }
            return list;
        },
    },
    watch: {
        tabActiveProps: {
            handler(newVal) {
                if (newVal) {
                    this.$emit('changetabActiveProps', newVal);
                    this.$store.dispatch('project/get_listProject', {
                        type: 'calling',
                        project_service: newVal,
                    });
                }
            },
        },
        page: {
            handler(newVal) {
                if (newVal) {
                    this.$store.dispatch('project/get_listProject', {
                        type: 'calling',
                        project_service: this.tabActiveProps,
                        page: newVal,
                    });
                }
            },
        },
    },
    methods: {
        onWindowResize() {
            this.width = window.innerWidth;
        },
    },
    mounted() {
        this.onWindowResize();
    },
    created() {
        window.addEventListener('resize', this.onWindowResize);
        this.$store.dispatch('project/get_listProject', {
            type: 'calling',
            project_service: this.tabActiveProps,
        });
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
};
</script>
<template>
    <b-container class="invest-list">
        <div class="category">
            <div class="tab-heading">
                <div
                    class="tab-item"
                    v-for="item in ListService"
                    :key="`header-project-${item.service_id}`"
                    :class="
                        tabActiveProps == item.service_id ? 'tab-active' : ''
                    "
                    @click="tabActiveProps = item.service_id"
                >
                    {{ item.service_name }}
                </div>
                <div class="tab-item more-category">
                    <b-button> Khác </b-button>
                </div>
            </div>
            <b-row
                class="category-content"
                v-if="
                    ListProject &&
                    ListProject.list &&
                    ListProject.list.data &&
                    ListProject.list.data.length > 0
                "
            >
                <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                    v-for="project in ListProject.list.data"
                    :key="`project-${project.project_id}`"
                >
                    <div class="project-item">
                        <div class="project-img">
                            <img
                                :src="
                                    ListProject.base_url + project.project_avata
                                "
                                alt=""
                            />
                            <div class="content-project">
                                <h3>{{ project.project_name }}</h3>
                                <p
                                    v-html="project.project_introduce"
                                    class="mb-0"
                                ></p>
                            </div>
                        </div>
                        <div class="project-name">
                            <router-link
                                :to="{
                                    name: 'ProjectDetail',
                                    params: { id: project.project_slug },
                                }"
                            >
                                <p>
                                    {{ project.project_name }}
                                </p>
                            </router-link>
                        </div>
                        <div class="project-line"></div>
                    </div>
                </b-col>
                <b-col cols="12">
                    <div class="pagination">
                        <div
                            v-for="indexPage in ListProject.list.last_page"
                            :key="indexPage"
                            class="dot"
                            :class="page == indexPage ? 'dot-active' : ''"
                            @click="page = indexPage"
                        ></div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="category-content" v-else>
                <b-col cols="12">{{ $t('temporarilyNoProjects') }}</b-col>
            </b-row>
        </div>
    </b-container>
</template>

<style lang="scss" scoped>
.invest-list {
    @media (max-width: 767px) {
        max-width: calc(100% - 20px);
    }
    .category {
        width: 100%;
        padding: 50px 0px;
        text-align: center;
        @media (max-width: 575px) {
            padding: 30px 0px;
        }
        h3 {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 40px;
            @media (max-width: 575px) {
                font-size: 30px;
            }
        }
        .tab-heading {
            width: max-content;
            max-width: 100%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            height: 50px;
            box-shadow: 0px 0px 5px #0000005a;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: 30px auto 0;
            overflow-x: auto;
            @media (max-width: 991px) {
                justify-content: inherit;
            }
            @media (max-width: 575px) {
                height: 40px;
            }
            @media (max-width: 414px) {
                width: 100%;
            }
            .tab-item {
                max-width: max-content;
                min-width: 150px;
                padding: 0 15px;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #363795;
                font-weight: bold;

                @media (max-width: 575px) {
                    width: 150px;
                    height: 40px;
                    font-size: 16px;
                }
                @media (max-width: 414px) {
                    flex: 1 1 auto;
                    width: 100%;
                    max-width: unset;
                }
                &.tab-active {
                    background-image: linear-gradient(
                        to right,
                        #363795,
                        #0040ff
                    );
                    color: #ffffff;
                }
                cursor: pointer;
                &:first-child {
                    border-top-left-radius: 20px;
                }
                &:last-child {
                    border-top-right-radius: 20px;
                }
            }
            .more-category {
                min-width: unset !important;
                padding: 0px;
                flex: 0 0 70px;
                button {
                    padding: 0;
                    background: #fff;
                    text-decoration: none;
                    transition: all 0.5s;
                    height: 100%;
                    width: 70px;
                    border-radius: 0;
                    border-top-right-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
                    color: #000;
                    font-weight: 600;
                    text-decoration: none;
                    font-size: 18px;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    box-shadow: 1px 2px 10px 2px
                        rgba($color: #000000, $alpha: 0.3);

                    img {
                        width: 50px;
                    }
                }
            }
        }
        .category-content {
            padding: 50px 100px;
            box-shadow: 1px 1px 5px #0000005a;
            border-radius: 10px;
            @media (max-width: 991px) {
                padding: 20px;
            }
            @media (max-width: 575px) {
                padding: 20px 0px;
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px auto;
                width: 100%;
                max-width: 250px;
                @media (max-width: 575px) {
                    max-width: 300px;
                }
                .project-img {
                    position: relative;
                    width: 100%;
                    height: 200px;
                    cursor: pointer;
                    &:hover {
                        .content-project {
                            opacity: 1;
                            transition: all 1s;
                        }
                    }
                    img {
                        position: absolute;
                        border-radius: 10px;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    .content-project {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #00000076;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        h3 {
                            color: #ffff;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            color: #ffff;
                            font-size: 14px;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-bottom: 0px;
                        }
                    }
                }
                .project-name {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    a {
                        p {
                            color: #000000;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 30px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-weight: bold;
                            font-size: 20px;
                            margin-bottom: 0px;
                            @media (max-width: 575px) {
                                font-size: 16px;
                                line-height: 25px;
                            }
                        }
                    }
                }
                .project-line {
                    width: 100%;
                    height: 8px;
                    background-color: #363795;
                    border-radius: 10px;
                }
            }
        }
        .pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
            @media (max-width: 575px) {
                margin-top: 20px;
            }
            .dot {
                cursor: pointer;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #999999;
                margin: 0px 10px;
                &.dot-active {
                    background-color: #363795;
                }
            }
        }
    }
}
</style>
